<template>
	<div class="nearBy" :style="{height:`${mapHeight-110}px`}">
		<div class="left map-container" ref="modalMap" id="modalMap">
			<baidu-map :center="mapCenter" :zoom="setZoom" @ready='loadComplete' :scroll-wheel-zoom='true'
				:max-zoom='19' :min-zoom='5'>
				<bm-view class="map"></bm-view>
				<bm-circle :center="position" strokeColor='#1eaef5' :strokeWeight='1' :strokeOpacity='0.5'
					fillColor='#1EAEF5' :fillOpacity='0.5' :radius="radius*1000"></bm-circle>
				<bm-control anchor="BMAP_ANCHOR_BOTTOM_LEFT" :offset='{width: 300,height: 20}'>
					<RadioBox @radioChange='setRadius' :radius='radius'></RadioBox>
				</bm-control>
				<bm-marker :key='mark.uid' v-for="mark in markList" :position="mark.location"
					:top='activeMark == mark.uid ? true :false' @click='setActiveMark(mark.uid)'>
					<bm-label :content="mark.name"
						:labelStyle="activeMark == mark.uid ? activeMarkStyle : nomalMarkStyle"
						:offset="{width: 25, height: 0}" />
				</bm-marker>
				<!-- <bm-marker  :position="position" :zIndex='999' animation="BMAP_ANIMATION_BOUNCE" :top='true'>
					<bm-label :content="landInfo.area" :offset="{width: 25, height: 0}" />
				</bm-marker> -->
			</baidu-map>
		</div>
		<div class="right">
			<SearchKey ref='SearchKey' @getSearchkey='getSearchkey' :markList='markList' @setActiveMark='setActiveMark'
				:activeMark='activeMark'></SearchKey>
		</div>
	</div>
</template>

<script>
	import RadioBox from './radioBox.vue'
	import SearchKey from './searchKey.vue'
	import {
		land_data_retrieve
	} from '../../../../../api/reLand.js'
	export default {
		name: 'nearBy',
		components: {
			RadioBox,
			SearchKey
		},
		props: {
			center: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		data() {
			return {
				mapHeight: document.querySelector('#reLand').offsetHeight,
				loaded: false,
				map: '',
				BMap: '',
				tools: '',
				radius: 1,
				keyWorld: [],
				position: {
					lng: 113.280637,
					lat: 23.125178
				},
				mapCenter: {
					lng: 113.280637,
					lat: 23.125178
				},
				page_num: 0,
				size: 20,
				total: 0,
				markList: [],
				activeMark: 0,
				nomalMarkStyle: {
					color: 'black',
					fontSize: '12px',
					paddingLeft: '10px',
					paddingRight: '10px',
					borderColor: '#FF7A38',
					backgroundColor: 'white',
				},
				activeMarkStyle: {
					color: 'white',
					fontSize: '12px',
					paddingLeft: '10px',
					paddingRight: '10px',
					borderColor: '#FF7A38',
					backgroundColor: '#E4291B',
				},
				setZoom: 15
			}
		},
		mounted() {
			const _this = this
			window.onresize = () => {
				return (() => {
					_this.mapHeight = document.querySelector('#reLand').offsetHeight
				})()
			}
			this.$nextTick(() => {
				this.mapHeight = document.querySelector('#reLand').offsetHeight
			})
		},
		activated() {
			this.searchByRadius(this.radius)
			this.setCenter(this.center)
			this.$refs.SearchKey.changeTab({
				name: '教育',
				keyWorld: ['幼儿园', '小学', '中学', '高等院校', '其他'],
				other: ['科研机构', '图书馆', '科技馆', '亲子教育', '培训机构']
			})
		},
		watch: {
			radius(val) {
				this.searchByRadius(val)
			}
		},
		methods: {
			loadComplete({
				BMap,
				map
			}) {
				this.loaded = true
				this.map = map
				this.BMap = BMap
				this.tools = new BMap.Geocoder()
				this.setZoom = 15
				this.setCenter(this.center)
			},
			setCenter(ponit) {
				setTimeout(() => {
					this.position = ponit
					this.mapCenter = ponit
				}, 500)
			},
			//设置半径
			setRadius(e) {
				this.radius = e
			},
			searchByRadius(radius) {
				this.searchNearBy(radius, this.keyWorld)
			},

			//查询周边配套
			async searchNearBy(radius, keyWorld) {
				let {
					lat,
					lng
				} = this.center
				let params = {
					radius: radius * 1000,
					location: `${lat},${lng}`,
					query: keyWorld.join(','),
					// tag: keyWorld.join(','),
					page_num: this.page_num,
					page_size: this.size,
					filter: 'sort_name:distance|sort_rule:1',
					radius_limit: true,
					scope: 2
				}
				let data = await land_data_retrieve(params)
				this.total = data.total
				this.markList = []
				data.results.forEach((item, index) => {
					//active控制是否选中
					item.active = false
					item.index = index
					this.markList.push(item)
				})
			},
			//获取查询的关键字
			getSearchkey(keyWorld) {
				if (keyWorld.length != 0) {
					this.keyWorld = keyWorld.filter(item => {
						return item != '其他'
					})
				}
				this.searchNearBy(this.radius, this.keyWorld)
			},
			//设置选中的mark
			setActiveMark(e) {
				this.activeMark = e
				//把地图移到选择的点
				// let mark = this.markList.filter(item => {
				// 	return item.uid == e
				// })
				// if (mark.length) {				
				// 	this.mapCenter = mark[0].location
				// }
			}
		},
	}
</script>

<style scoped lang="scss">
	.nearBy {
		width: 100%;
		margin-top: 20px;
		position: relative;
	}

	.map-container {
		height: 100%;
		overflow: hidden;

		.map {
			width: 100%;
			height: 100%;

		}
	}

	.map-container>div {
		width: 100%;
		height: 100%;
	}

	.left {
		margin-right: 350px;
	}

	.right {
		width: 350px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;

	}
</style>
