<template>
	<div class="searchKey">
		<div class="tabBox">
			<div v-for="(item,index) in tabList" :key="index" @click="changeTab(item)"
				:class="['tab',activeTab == item.name ? 'active' : '']">
				<span class="tabName">{{item.name}}</span>
			</div>
		</div>
		<div class="_content">
			<div class="checkBox">
				<CheckboxGroup v-model="selectedKey" @on-change="checkAllGroupChange">
					<Checkbox class='checkItem' :indeterminate="indeterminate" :value="checkAll"
						@click.prevent.native="handleCheckAll">全选
					</Checkbox>
					<Checkbox class='checkItem' :key='index' v-for="(item,index) in activeCheckBoxList" :label="item">
					</Checkbox>
				</CheckboxGroup>
			</div>

			<div class="markWrapper">
				<div class="markBox">
					<div v-if="markList.length != 0">
						<div :key='index' :class="['mark',activeMark == item.uid ? 'acitve':'']"
							v-for="(item,index) in markList" @click="setActiveMark(item.uid)">
							<div class="item left">
								<div class="name">
									{{item.name}}
								</div>
								<div class="tag">
									{{item.detail_info.tag}}
								</div>
							</div>
							<div class="item right">
								<Icon type="ios-pin" style="color: #1EAEF5;" />
								<div class="distance"><span style="color: #1EAEF5;">{{item.detail_info.distance}}</span>米
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="noData">
							暂无数据
						</div>
					</div>
					
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'searchKey',
		data() {
			return {
				tabList: [{
					name: '教育',
					keyWorld: ['幼儿园', '小学', '中学', '高等院校', '其他'],
					other: ['科研机构', '图书馆', '科技馆', '亲子教育', '培训机构']
				}, {
					name: '医疗',
					keyWorld: ['综合医院', '三甲医院', '专科医院', '药店', '疗养院', '体检机构', '急救中心', '疾控中心', '医疗保健'],
					other: []
				}, {
					name: '商业',
					keyWorld: ['购物', '酒店', '美食', '休闲娱乐', '银行', '写字楼'],
					other: []
				}, {
					name: '交通',
					keyWorld: ['地铁站', '公交站', '火车站', '长途汽车站', '飞机场', '港口', '停车场'],
					other: []
				}, {
					name: '特殊',
					keyWorld: ['加油站', '液化气供应站', '垃圾场', '核电厂', '变电站', '殡仪服务', '化工厂', '危险品仓库', '屠宰场', '污水处理厂'],
					other: []
				}],
				//活动的tab
				activeTab: '教育',
				//默认选择的tab
				selectedTab: {
					name: '教育',
					keyWorld: ['幼儿园', '小学', '中学', '高等院校', '其他'],
					other: ['科研机构', '图书馆', '科技馆', '亲子教育', '培训机构']
				},
				//选择的列表
				activeCheckBoxList: ['幼儿园', '小学', '中学', '高等院校', '其他'],
				//选择的key
				selectedKey: [],
				indeterminate: false,
				checkAll: false,
			}
		},
		props: {
			markList: {
				type: Array,
				default: () => {
					return []
				}
			},
			activeMark:{
				type:[Number,String],
			}
		},
		watch: {
			selectedKey: {
				handler(val) {
					//重置选择状态
					this.setActiveMark(0)		
					let res = JSON.parse(JSON.stringify(val))
					let other = []
					//当有选择时，传选择的值
					if (val.length > 0) {
						//当选择‘其他‘时，把其他的选项加上
						val.forEach(item => {
							if (item == '其他') {
								other = this.selectedTab.other
							}
						})
						this.$emit('getSearchkey', res.concat(other))
					} else {
						//当没有选择时，默认选择所有
						let keyWorld = JSON.parse(JSON.stringify(this.selectedTab.keyWorld))
						let other = JSON.parse(JSON.stringify(this.selectedTab.other))
						this.$emit('getSearchkey', keyWorld.concat(other))
					}
				},
				immediate: true
			}
		},
		methods: {
			changeTab(tab) {
				this.selectedTab = tab
				this.activeTab = this.selectedTab.name
				this.activeCheckBoxList = this.selectedTab.keyWorld
				this.selectedKey = []
				this.indeterminate = false
				this.checkAll = false
			},
			handleCheckAll() {
				if (this.indeterminate) {
					this.checkAll = false;
				} else {
					this.checkAll = !this.checkAll;
				}
				this.indeterminate = false;
				if (this.checkAll) {
					this.selectedKey = this.selectedTab.keyWorld;
				} else {
					this.selectedKey = [];
				}
			},
			setActiveMark(uid) {
				this.$emit('setActiveMark',uid)
			},
			checkAllGroupChange(data) {
				if (data.length === this.selectedTab.keyWorld.length) {
					this.indeterminate = false;
					this.checkAll = true;
				} else if (data.length > 0) {
					this.indeterminate = true;
					this.checkAll = false;
				} else {
					this.indeterminate = false;
					this.checkAll = false;
				}
			}
		},
	}
</script>

<style scoped lang="scss">
	.searchKey {
		width: 100%;
		height: 100%;
	}

	.tabBox {
		display: flex;

		.tab {
			flex: 1;
			text-align: center;
			cursor: pointer;
			height: 60px;
			line-height: 60px;

			.tabName {
				height: 30px;
				padding: 5px 0;
			}

			&.active {
				color: #1EAEF5;

				.tabName {
					border-bottom: 1px solid #1EAEF5;
				}
			}
		}
	}

	._content {
		height: calc(100% - 60px);
		padding: 20px;
		background-color: #ffffff;
		display: flex;
		flex-direction: column; //竖轴方向
	}

	.checkBox {
		padding-bottom: 20px;
		border-bottom: 1px solid #333333;
	}

	.checkItem {
		display: inline-block;
		vertical-align: top;
	}
	.noData{
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		color: #999;
	}

	.markWrapper {
		height: 100%;
		overflow: auto;

		.markBox {
			margin-top: 15px;

			.mark {
				width: 100%;
				padding: 15px 0;
				height: 42px;
				cursor: pointer;
				box-sizing: content-box;

				.item {
					height: 42px;
					display: inline-block;
					vertical-align: middle;
				}

				.left {
					width: 220px;
					float: left;

					.name {
						height: 24px;
						font-size: 16px;
						font-family: Source Han Sans CN, Source Han Sans CN-Regular;
						font-weight: 400;
						text-align: left;
						color: #333333;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					.tag {
						height: 12px;
						font-size: 12px;
						font-family: Source Han Sans CN, Source Han Sans CN-Regular;
						font-weight: 400;
						text-align: left;
						color: #999999;
					}
				}

				.right {
					float: right;
					width: 70px;
					margin-right: 2px;

					.icon {
						display: inline-block;
						width: 20px;
						height: 20px;
						background-color: deepskyblue;
						vertical-align: middle;
					}

					.distance {
						width: 50px;
						vertical-align: middle;
						line-height: 21px;
						font-size: 14px;
						font-family: Source Han Sans CN, Source Han Sans CN-Regular;
						font-weight: 400;
						text-align: right;
						color: #333333;
						display: inline-block;
					}
				}
				&.acitve{
					.left{
						.name{
							color: #1EAEF5;
						}
					}
				}
			}
		}
	}
</style>
