<template>
	<div class="radioWrapper">
		<div class="radiusBox">
			<span class="radiusText">周边范围</span>
			<RadioGroup style='position: relative;top: -2px;' v-model="num">
				<Radio style='margin-right: 30px;font-size: 16px;' :label="1">1公里</Radio>
				<Radio style='margin-right: 30px;font-size: 16px;' :label="2">2公里</Radio>
				<Radio style='margin-right: 30px;font-size: 16px;' :label="3">3公里</Radio>
			</RadioGroup>
			<InputNumber style='font-size: 16px;text-align: center; position: relative;top: -4px;' :max="5" :min="0.1" :step="0.5"
				:active-change='false' controls-outside v-model="num">
			</InputNumber>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'radioWrapper',
		props: {
			radius: {
				type: Number,
				default: 1
			},
		},
		watch: {
			radius(val) {
				this.num = val
			}
		},
		computed: {
			num: {
				get() {
					return this.radius
				},
				set(val) {
					this.$emit('radioChange', val)
				}
			}
		},
	}
</script>

<style scoped lang="scss">
	.radiusBox {
		width: 570px;
		height: 60px;
		background: #ffffff;
		border-radius: 6px;
		box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.20);

		.radiusText {
			display: inline-block;
			margin: 20px 30px 0 30px;
			font-size: 16px;
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			color: #999999;
		}
	}
	/deep/ .ivu-input-number-input {
	text-align: center;
}
</style>
