<template>
	<div class="landInfo">
		<div class="chuck">
			<div class="title" style="color: #1EAEF5;">
				{{data.landName}}
				<span :class="['start',data.isAttention?'active':'']" @click="attention(data)"
					:title="data.isAttention?'取消关注':'关注地块'"></span>
			</div>
			<div class="chuckInfo">
				<div class="tagBox">
					<div v-if="data.landUseName" class="detail">
						<div class="_label">土地用途：</div>
						<div class="_info">{{data.landUseName}}</div>
					</div>
					<div v-if="data.transactionPrice" class="detail">
						<div class="_label">成交价：</div>
						<div class="_info">{{data.transactionPrice}}万元</div>
					</div>
					<div v-if="data.floorPrice" class="detail">
						<div class="_label">楼面价：</div>
						<div class="_info">{{data.floorPrice}}元/m²</div>
					</div>
					<div v-if="data.startPrice" class="detail">
						<div class="_label">起始价：</div>
						<div class="_info">{{data.startPrice}}万元</div>
					</div>
					<div v-if="data.premiumRate" class="detail">
						<div class="_label">溢价率：</div>
						<div class="_info">{{data.premiumRate | rateFormat}}</div>
					</div>
					<div v-if="data.buildingArea" class="detail">
						<div class="_label">建筑面积：</div>
						<div class="_info">{{data.buildingArea}}m²</div>
					</div>
					<div v-if="data.constructionLandArea" class="detail">
						<div class="_label">建筑用地面积：</div>
						<div class="_info">{{data.constructionLandArea}}m²</div>
					</div>
				</div>
				<div class="textBox">
					<div v-if="data.transactionInfo.assignee" class="detail">
						<div class="_label">受让方：</div>
						<div class="_info">{{data.transactionInfo.assignee}}</div>
					</div>
					<!-- <div class="detail">
						<div class="_label">开发商：</div>
						<div class="_info">中国金茂、碧桂园</div>
					</div> -->
				</div>

			</div>
		</div>
		<div class="chuck">
			<div class="title">
				基本信息
			</div>
			<div class="chuckInfo">
				<div class="detailBox">
					<div class="detail">
						<div class="_label">所属城市：</div>
						<div class="_info">{{data.basicInfo.city ? data.basicInfo.city : '--'}}</div>
					</div>
					<div class="detail">
						<div class="_label">行政区：</div>
						<div class="_info">{{data.basicInfo.district ? data.basicInfo.district : '--'}}</div>
					</div>
					<div class="detail">
						<div class="_label">土地用途：</div>
						<div class="_info">{{data.landUseName ? data.landUseName : '--'}}</div>
					</div>
					<div class="detail">
						<div class="_label">土地用途明细：</div>
						<div class="_info">
							{{data.basicInfo.landUseDetail ? data.basicInfo.landUseDetail : '--'}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">建筑面积：</div>
						<div class="_info">
							{{data.basicInfo.buildingArea ? data.basicInfo.buildingArea :' --'}}m²
						</div>
					</div>
					<div class="detail">
						<div class="_label">土地面积：</div>
						<div class="_info">{{data.basicInfo.landArea ? data.basicInfo.landArea : '--'}}m²</div>
					</div>
					<div class="detail">
						<div class="_label">建设用地面积：</div>
						<div class="_info">
							{{data.basicInfo.constructionLandArea ? data.basicInfo.constructionLandArea : '--'}}m²
						</div>
					</div>
					<div class="detail">
						<div class="_label">绿化率：</div>
						<div class="_info">{{data.basicInfo.greeningRate ? data.basicInfo.greeningRate : '--'}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">容积率：</div>
						<div class="_info">{{data.basicInfo.volumeRate | priceFormat}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">建筑密度：</div>
						<div class="_info">
							{{data.basicInfo.buildingDensity ? data.basicInfo.buildingDensity : '--'}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">使用年限：</div>
						<div class="_info">{{data.basicInfo.landUseLimit ? data.basicInfo.landUseLimit : '--'}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">地块位置：</div>
						<div class="_info">{{data.basicInfo.landPosition ? data.basicInfo.landPosition : '--'}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="chuck">
			<div class="title">
				出让信息
			</div>
			<div class="chuckInfo">
				<div class="detailBox">
					<div class="detail">
						<div class="_label">宗地编号：</div>
						<div class="_info">
							{{data.transferInfo.landNumber ? data.transferInfo.landNumber : '--'}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">出让方式：</div>
						<div class="_info">
							{{data.transferInfo.transferWayName ? data.transferInfo.transferWayName : '--'}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">出让时间：</div>
						<div class="_info">
							{{data.transferInfo.transferDate | dataFormat}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">截止时间：</div>
						<div class="_info">
							{{data.transferInfo.expirationDate | dataFormat}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">起始价：</div>
						<div class="_info">
							{{data.transferInfo.startPrice ? data.transferInfo.startPrice : '--'}}万元
						</div>
					</div>
					<div class="detail">
						<div class="_label">推出楼面价：</div>
						<div class="_info">
							{{data.transferInfo.pushPrice | priceFormat}}元/m²
						</div>
					</div>
					<div class="detail">
						<div class="_label">加价幅度：</div>
						<div class="_info">{{data.transferInfo.markup ? data.transferInfo.markup : '--'}}</div>
					</div>
					<div class="detail">
						<div class="_label">保证金：</div>
						<div class="_info">
							{{data.transferInfo.promisePrice ? data.transferInfo.promisePrice : '--'}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="chuck">
			<div class="title">
				成交信息
			</div>
			<div class="chuckInfo">
				<div class="detailBox">
					<div class="detail">
						<div class="_label">交易状态：</div>
						<div class="_info">
							{{data.transactionInfo.transactionStateName ? data.transactionInfo.transactionStateName : '--'}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">成交时间：</div>
						<div class="_info">
							{{data.transactionInfo.transactionDate | dataFormat}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">成交价：</div>
						<div class="_info">
							{{data.transactionPrice ? data.transactionPrice : '--'}}万元
						</div>
					</div>
					<div class="detail">
						<div class="_label">楼面价：</div>
						<div class="_info">
							{{data.transactionInfo.floorPrice ? data.transactionInfo.floorPrice : '--'}}元/m²
						</div>
					</div>
					<div class="detail">
						<div class="_label">溢价率：</div>
						<div class="_info">
							{{data.transactionInfo.premiumRate | rateFormat}}
						</div>
					</div>
					<div class="detail">
						<div class="_label">受让方：</div>
						<div class="_info">{{data.transactionInfo.assignee ? data.transactionInfo.assignee : '--'}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 		<div class="chuck">
			<div class="title">
				公告文件
			</div>
			<div class="chuckInfo">
				<div class="fileBox">
					<div class="file">
						<div class="fileTitle">
							招标/挂牌/拍卖公告
						</div>
						<div :class="['fileIcon','png']"></div>
						<div class="fileName">
							TD2018(SD)XG0003佛山新城从...
						</div>
					</div>

					<div class="file">
						<div class="fileTitle">
							招标/挂牌/拍卖公告
						</div>
						<div :class="['fileIcon','jpg']"></div>
						<div class="fileName">
							TD2018(SD)XG0003佛山新城从...
						</div>
					</div>
					<div class="file">
						<div class="fileTitle">
							招标/挂牌/拍卖公告
						</div>
						<div :class="['fileIcon','folder']"></div>
						<div class="fileName">
							TD2018(SD)XG0003佛山新城从...
						</div>
					</div>
					<div class="file">
						<div class="fileTitle">
							招标/挂牌/拍卖公告
						</div>
						<div :class="['fileIcon','pdf']"></div>
						<div class="fileName">
							TD2018(SD)XG0003佛山新城从...
						</div>
					</div>
				</div>
			</div>
		</div>	 -->


		<ComfirmButton :customerStyle='customerStyle' ref='ComfirmButton' size='small' @comfirm='attention()' info=''>
			<div slot="content">
				<div class="nomalBox">
					<p class="text">{{row.isAttention ?'确认取消关注':'确认关注' }}</p>
				</div>
			</div>
		</ComfirmButton>
	</div>
</template>

<script>
	import util from '../../../../utils/utils2.js'
	import ComfirmButton from '../../../../components/Comfirm.vue'
	import {
		land_data_is_cancel_favorite
	} from '../../../../api/reLand.js'
	export default {
		name: 'landInfo',
		components: {
			ComfirmButton,
		},
		filters: {
			dataFormat(val) {
				if (val) {
					return util.formatDateFmt(
						new Date(val),
						"yyyy-MM-dd"
					)
				} else {
					return '--'
				}
			},
			rateFormat(val) {
				if (val !== '') {
					return `${(val*100).toFixed(2)}%`
				} else {
					return '--'
				}
			},
			priceFormat(val){
				if (val) {
					return `${(val*1).toFixed(2)}`
				} else {
					return '--'
				}
			}
		},
		props: {
			landInfo: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		watch: {
			landInfo: {
				handler(val) {
					this.data = val
				},
				deep: true
			}
		},
		data() {
			return {
				jpg: false,
				data: {
					transactionInfo: {},
					basicInfo: {},
					transferInfo: {},
				},
				row: {},
				customerStyle: {
					'opacity': 0
				},
			}
		},
		methods: {
			showComfirm(row) {
				this.row = row
				this.$refs.ComfirmButton.toggle()
			},
			//关注or取消
			async attention(row) {
				row.isCancel = row.isAttention
				row.isAttention = !row.isAttention
				let data = await land_data_is_cancel_favorite(row)
				if (data) {
					this.$msg.success({
						text: row.isAttention ? '关注成功' : '取消关注成功'
					})
					this.getFavoriteData()
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	.chuck {
		width: 100%;
		position: relative;
		left: 50%;
		transform: translate(-50%);
		background: #ffffff;
		border-radius: 8px;
		box-shadow: -4px 0px 24px 0px rgba(51, 51, 51, 0.05);
		padding: 20px 30px;
		margin-top: 20px;
	}

	.title {
		font-size: 18px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: #999999;
		line-height: 48px;
		border-bottom: 1px #cdcdcd solid;

		.start {
			cursor: pointer;
			display: inline-block;
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: auto;
			background-image: url(../../../../assets/image/land/start.png);

			&.active {
				background-image: url(../../../../assets/image/land/start_active.png);
			}
		}
	}

	.text {
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		padding-top: 50px;
		padding-bottom: 30px;
	}

	.chuckInfo {
		padding: 20px 0 0;
	}

	.detail {
		display: inline-block;
		padding: 5px 15px;
		margin-right: 20px;

		._label {
			display: inline-block;
			font-size: 14px;
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			color: #999999;
		}

		._info {
			display: inline-block;
			font-size: 14px;
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			color: #333333;
		}
	}

	.tagBox {
		.detail {
			background-color: rgba($color: #333333, $alpha: 0.05);
			border-radius: 15px;

			._label {
				color: #747C8A;
			}

			._info {
				color: #747C8A;
			}
		}
	}

	.textBox {
		margin-top: 20px;

		.detail {
			._label {
				color: #747C8A;
			}

			._info {
				color: #747C8A;
			}
		}

	}

	.detailBox {
		.detail {
			vertical-align: top;

			._label {
				width: 100px;
				text-align: right;
				vertical-align: top;
			}

			._info {
				width: 200px;
				text-align: left;
				vertical-align: top;
			}
		}
	}

	.fileBox {
		margin-top: 20px;

		.file {
			width: 250px;
			height: 250px;
			display: inline-block;
			margin: 0 60px;

			.fileTitle {
				text-align: center;
				font-size: 14px;
				font-family: Source Han Sans CN, Source Han Sans CN-Regular;
				font-weight: 400;
				color: #999999;
			}

			.fileIcon {
				width: 100px;
				height: 200px;
				background-repeat: no-repeat;
				background-position: center;
				margin: auto;

				&.folder {
					background-image: url('../../../../assets/image/land/folder.png');
				}

				&.jpg {
					background-image: url('../../../../assets/image/land/jpg.png');
				}

				&.pdf {
					background-image: url('../../../../assets/image/land/pdf.png');
				}

				&.png {
					background-image: url('../../../../assets/image/land/png.png');
				}
			}

			.fileName {
				text-align: center;
				font-size: 14px;
				font-family: Arial, Arial-Regular;
				font-weight: 400;
				color: #333333;
			}
		}
	}
</style>
