<template>
	<div class="landDetail">
		<div class="btnBox">
			<div class="black" @click="black"> &lt; </div>
			<div @click="toDetail(1)" :class="['btn' ,'btn2',btn == 1 ? 'active' : '']">地块详情</div>
			<div @click="toDetail(0)" style="margin-left: -1px;" :class="['btn' ,'btn1',btn == 0 ? 'active' : '']">周边配套
			</div>
		</div>
		<div class="contentBox">
			<keep-alive>
				<component :ref='componentTag' :is="componentTag" :landInfo='landInfo' :center='center'></component>
			</keep-alive>
		</div>
	</div>
</template>

<script>
	import LandInfo from './components/landInfo.vue'
	import NearBy from './components/nearBy/index.vue'
	import {
		land_data_details,
		land_data_query_center
	} from '../../../api/reLand.js'
	export default {
		name: 'landDetail',
		components: {
			LandInfo,
			NearBy,
		},
		watch: {
			btn(val) {
				if (val == 0) {
					this.componentTag = 'NearBy'
				} else if (val == 1) {
					this.componentTag = 'LandInfo'
				}
			}
		},
		mounted() {
			this.init()
		},
		activated() {
			this.init()
		},
		data() {
			return {
				btn: 1,
				componentTag: 'LandInfo',
				landInfo: {},
				center: {
					lng: 0,
					lat: 0
				},
				canGoNearBy: false,
				errText: '',
			}
		},
		methods: {
			init() {
				this.getParams()
				this.btn = 1
			},
			getParams() {
				let {
					landRelationKey,
					landPosition,
					transactionStateCode
				} = this.$route.query
				this.getDetail(landRelationKey, transactionStateCode)
				this.getCenter(landPosition)
			},
			async getDetail(landRelationKey, transactionStateCode) {
				let data = await land_data_details({
					landRelationKey: landRelationKey,
					transactionStateCode: transactionStateCode
				})
				this.landInfo = data
			},
			async getCenter(landPosition) {
				this.canGoNearBy = false
				land_data_query_center({
					landPosition: landPosition
				}).then(res => {
					if (res.lat && res.lng) {
						this.canGoNearBy = true
						this.center = res
					}else{
						this.canGoNearBy = false
						this.errText = '暂无数据'
					}
				}).catch(err => {
					this.canGoNearBy = false
					this.errText = err.data
				})
			},
			toDetail(e) {
				if (e == 0 & !this.canGoNearBy) {
					this.$msg.error({
						text: this.errText
					})
					return
				}
				this.btn = e
			},
			black() {
				this.$router.back()
			}
		},

	}
</script>

<style scoped lang="scss">
	.landDetail {
		background: #f0f3f8;
		padding: 25px 30px;
		min-height: 100%;
	}

	.btnBox {
		width: 300px;
		height: 40px;

		.black {

			display: inline-block;
			width: 20px;
			height: 20px;
			background-color: rgba($color: #1EAEF5, $alpha: 0.2);
			border-radius: 50%;
			color: #1EAEF5;
			font-weight: 900;
			font-size: 16px;
			text-align: center;
			line-height: 20px;
			margin-right: 30px;
			cursor: pointer;
		}

		.btn {
			display: inline-block;
			width: 120px;
			height: 40px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% auto;
			font-size: 18px;
			text-align: center;
			line-height: 40px;
			border: 1px solid #00b6ff;
			color: #00b6ff;
			background-color: rgba($color: #ffffff, $alpha: 1);
			cursor: pointer;

			&.active {
				color: #FFFFFF;
				background-color: rgba($color: #00b6ff, $alpha: 1);
			}

			&.btn1 {
				border-bottom-right-radius: 10px;
				border-top-right-radius: 10px;
			}

			&.btn2 {
				border-bottom-left-radius: 10px;
				border-top-left-radius: 10px;
			}

		}
	}
</style>
